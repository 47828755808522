@import '../../styles/variables.scss';

.me {
    .title {
        padding-top: 20px;
    }
    .content {
        position: relative;

        .bloc {
            background-color: rgba(255, 255, 255, 0.4);
            font-size: 1.2rem;
            line-height: 1.8rem;
            margin: 0;
            padding: 1rem 0.5rem;
            position: absolute;

            @media #{$mq-medium-and-over} {
                font-size: 1.5rem;
                line-height: 2.4rem;
                padding: 3.8rem 2rem;
            }

            &.blocLeft {
                bottom: 1rem;
                left: 0;

                @media #{$mq-medium-and-over} {
                    bottom: 6rem;
                    left: 4rem;
                }
            }

            &.blocRight {
                bottom: 1rem;
                right: 0;

                @media #{$mq-medium-and-over} {
                    bottom: 6rem;
                    right: 1rem;
                }
            }
        }

        .image {
            border: 0 none;
            display: block;
            height: auto;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }
}
