@import '../../styles/variables';

.me {
    border-top: 10px solid $couleur-primaire-dark;
    margin-top: 60px;
    padding-bottom: 60px;
    padding-top: 60px;

    @media print {
        display: none;
    }
}
