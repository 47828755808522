@import '../../styles/variables.scss';

.me {
    width: 100%;
    display: grid;
    gap: $grid-column-gap;
    grid-template-columns: repeat(1, 1fr);

    @media #{$mq-medium-and-over} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media #{$mq-large-and-over} {
        grid-template-columns: repeat(3, 1fr);
    }
}
